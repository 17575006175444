import React from "react";
import Style from "./style"

function CaseStudiesMain() {
    return(
        <div className="container-fluid p-0 about-main-container contact-us-header">
            <div className=" case-studies-header">
                <div className="container">


                    <div className="about-head-text">

                        <h1 className="services-detail-text-big">Rocky Mountain Mineral Law Foundation</h1>
                    </div>
                </div>

                <div className="col-sm-12 case-red-flex p-0">
                    <div className="col-sm-6 case-red-col">
                        <p className="services-detail-text">Short Description of what the case study about? Lorem Ipsum. Proin gravida nibh vel velit auctor
                            aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat</p>
                        <hr/>
                            <p className="services-detail-text">Enterprise Web, Products & Services</p>
                    </div>
                    <div className="col-sm-6">
                        <p className="opacity-bg-num">06</p>
                    </div>
                </div>

            </div>

            <Style/>
        </div>
    )
}
 export default CaseStudiesMain;