import React from 'react'

export default () => (
    <style jsx="true">{`
.case-studies-header{
    background: white!important;
    min-height: 410px;
    padding-top:150px;
}
.case-studies-header .services-detail-text-big {
    font-size: 44px;
    color: #5a5a5a;
    font-weight: 800;
    margin: 0 0 25px;
}
.case-red-flex{
    display: flex;flex-wrap: wrap
}
.case-red-flex .case-red-col {
    background: linear-gradient(to right, rgba(182,46,64,1) 0%, rgba(221,33,58,1) 100%);
    min-height: 40px;
}
.case-studies-header .services-detail-text{
color: white;
padding: 9px 10px 0px 136px;
}
.case-red-flex hr{
   background-color: rgba(255, 255, 255, 0.5);

}
.opacity-bg-num{
    position: absolute;
    bottom: 0;
    right: 35px;
    font-size: 152px;
    margin: 0;
    line-height: 130px;
    color: #f4f4f4;
    font-weight: 700;
}
    `}</style>
)