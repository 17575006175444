import React from "react";
import CaseStudiesMain from "../casestudiesmain";
import Vision from "../visionsection";
import CaseSlider from "../casestudyslider"
function caseStudiesComponent() {
    return(
        <div>
            <CaseStudiesMain/>
            <Vision casevision={"casevision"} visionbg={"visionbg"} visioncard={"visioncard"}/>
            <CaseSlider/>
        </div>
    )
}
export default caseStudiesComponent;