import React from 'react'

export default () => (
    <style jsx="true">{`
#case-slider .col-sm-6 {
    min-height: 350px;
}
#case-slider .case-slider-btn{
    background-color:black;
    color: white!important;
    padding: 16px 14px;
    cursor: pointer;
    font-size: 14px;
    border:none;
}
.slider-red-col{
    background: linear-gradient(to right, rgba(182,46,64,1) 0%, rgba(221,33,58,1) 100%);
}
#case-slider .col-sm-12{
    display: flex
}
.red-slider-div{
    padding: 73px 130px
}
#case-slider .col-sm-6 p{
    font-size: 32px;
    font-weight: 700;
    margin: 0;
    line-height: 50px;
    color: #b62e40;
    margin-top: 40px;
}
.slider-red-col p{
    color: white!important;
}
.case-study-container{
    background-color: #f4f4f4;
}
#case-slider .carousel-indicators{
display:none
}
  `}</style>
)