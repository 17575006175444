/**
 * Created by TeacEnclave Computer on 14/05/2019.
 */
import React from "react";
import Layout from "../components/layout";
import CaseStudiesComponent from "../components/casestudiescomponent"

function caseStudies () {
    return(
        <Layout headimg ={"/images/top-logo-2.webp"} headclr={"black-list"} headbg={"transparent-bg"} callicon ={"/images/call-answer (1).webp"}>
            <CaseStudiesComponent/>
        </Layout>
    )
}
export default caseStudies;