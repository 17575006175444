import React from "react";
import Style from "./style";
import { Carousel } from "react-bootstrap";
function caseSlider() {
    return(
        <div>
            <section className="case-study-slider">
                <Carousel id="case-slider">
                    <Carousel.Item>
                        <div className="col-sm-12 p-0">


                            <div className="col-sm-6 p-0">
                                <div className="red-slider-div">
                                    <button className="case-slider-btn">PREVIOUS CASE STUDY</button>
                                    <p>Arris Network Assistant Mobile App</p>
                                </div>
                            </div>
                            <div className="col-sm-6 slider-red-col p-0">
                                <div className="red-slider-div">
                                    <button className="case-slider-btn">NEXT CASE STUDY</button>
                                    <p>Arris Network Assistant Mobile App</p>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="col-sm-12 p-0">


                            <div className="col-sm-6 p-0">
                                <div className="red-slider-div">
                                    <button className="case-slider-btn">PREVIOUS CASE STUDY</button>
                                    <p>Arris Network Assistant Mobile App</p>
                                </div>
                            </div>
                            <div className="col-sm-6 slider-red-col p-0">
                                <div className="red-slider-div">
                                    <button className="case-slider-btn">PREVIOUS CASE STUDY</button>
                                    <p>Arris Network Assistant Mobile App</p>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </section>
            <Style/>
        </div>
    )
}
export default caseSlider;